<template>
  <div id="app" ref="app">
    <van-nav-bar
    v-if="$route.meta.layout === 'topOnly'"
    class="bg-primary text-white"
      :title="navbarTitle || $route.meta.title"
    />
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  metaInfo() {
    return {
      titleTemplate: (s) => (s ? `${s}` : 'VENTENY'),
    };
  },
  data() {
    return {
      navbarTitle: '',
    };
  },
  computed: {
    isForbidden() {
      return this.$store.getters.isForbidden;
    },
  },
  components: {
  },
};
</script>
<style>
#app {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 480px;
  min-height: 100vh;
  background-color: #fff;
  /* background-color: #f4f6fa; */
  /* text-align: center; */
  font-family: Nunito !important;
  /* padding-bottom: 50px; */
}
.van-nav-bar__title {
  color: #fff !important;
}
</style>