import ApiService from './api.service';

export const GET_FORM = 'getFormCampaign';
export const SUBMIT_FORM = 'submitFormCampaign';

const state = {};

const getters = {};

const actions = {
  [GET_FORM](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/campaign/${param.id}`, param.data)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [SUBMIT_FORM](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post(`api/campaign/${param.id}`, param.data, param.options)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};