import ApiService from './api.service';

export const REDEEM_PROMO_VOUCHER = 'redeemPromoVoucher';
export const REDEEM_VOUCHER = 'redeemVoucher';
export const REDEEM_VOUCHER_VENTENY = 'redeemVoucherVenteny';

const state = {};

const getters = {};

const actions = {
  [REDEEM_PROMO_VOUCHER](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/redeem-promo/${param.id}`)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [REDEEM_VOUCHER](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post('api/redeem-voucher', param)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [REDEEM_VOUCHER_VENTENY](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post('api/redeem-voucher/venteny', param)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};