import Vue from 'vue';
import VueRouter from 'vue-router';

// import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/404',
  },
  {
    path: '/campaign/:id',
    name: 'Campaign',
    meta: {
      title: 'VENTENY',
      layout: 'topOnly',
    },
    component: () => import('../ui/pages/Campaign.vue'),
  },
  {
    path: '/redeem/:promo_id',
    name: 'Voucher',
    meta: {
      title: 'VENTENY',
      layout: 'blank',
    },
    component: () => import('../ui/pages/RedeemVoucher.vue'),
  },
  {
    path: '/redeem/venteny/:id',
    name: 'Voucher Venteny',
    meta: {
      title: 'VENTENY',
      layout: 'blank',
    },
    component: () => import('../ui/pages/RedeemVoucherVenteny.vue'),
  },
  {
    path: '/redeem/:merchant_id/:voucher_id',
    name: 'Redeem Voucher',
    component: () => import('../ui/pages/RedeemVoucherApps.vue'),
  },
  {
    path: '/merchant',
    name: 'Register Merchant',
    meta: {
      layout: 'topOnly',
    },
    component: () => import('../ui/pages/merchant/Register.vue'),
  },
  {
    path: '/404',
    name: '404',
    meta: { layout: 'default', auth: true },
    component: () => import('../ui/pages/error/404.vue'),
  },
  {
    path: '*',
    redirect: '/404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
