import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import campaigns from './modules/campaigns';
import merchant from './modules/merchant';
import voucher from './modules/voucher';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    campaigns,
    merchant,
    voucher,
  },
  plugins: [createPersistedState()],
});
