import axios from 'axios';
import store from '..';
import { PURGE_AUTH, SET_FORBIDDEN } from './auth';
import { getToken } from './jwt.service';
import moment from 'moment';
import { SHA256 } from 'crypto-js';
import uuid from 'uuid/dist/v4';

const ApiService = {
  init() {
    axios.defaults.baseURL = process.env.VUE_APP_MICROSITE_API_URL;
    axios.defaults.timeout = 30000;
  },

  setHeader() {
    if (getToken()) {
      let token = getToken();
      try {
        if (token) token = JSON.parse(token);
      } catch (error) {
        console.log(error);
      }
      axios.defaults.headers.common.authorization = `Bearer ${typeof token === 'object' ? token.merchant : token}`;
    }
    // store.commit(SET_FORBIDDEN, false);
  },

  setSignature({ query, body }) {
    const timestamp = moment().format('X');
    const req_id = uuid();
    const str = [
      req_id,
      timestamp,
      JSON.stringify(query || {}),
      JSON.stringify(body || {}),
    ].join('|');
    const fileContent = SHA256(str).toString();
    axios.defaults.headers.common['x-signature'] = fileContent;
    axios.defaults.headers.common['x-timestamp'] = timestamp;
    axios.defaults.headers.common['x-request-id'] = req_id;
  },

  query(resource, params) {
    return axios.get(resource, params).catch((error) => {
      throw new Error(`ApiService ${error}`);
    });
  },

  async throwLogin(error) {
    if (error?.response?.status === 401) {
      store.commit(PURGE_AUTH);
      // window.location = '/login';
    } else if (error?.response?.status === 403) {
      // window.location = '/403';
      store.commit(SET_FORBIDDEN, true);
    }
    return error;
  },

  async get(resource, params, options = {}) {
    this.setHeader();
    this.setSignature({ query: params, ...options });
    return axios.get(`${resource}`, { params, ...options }).catch((error) => {
      this.throwLogin(error);
      throw error;
    });
  },
  post(resource, params, options = {}) {
    this.setHeader();
    this.setSignature({ body: params });
    return axios.post(`${resource}`, params, { ...options });
    // return axios({ method: 'post', url: `${resource}`, data: params, params: query });
  },

  upload(resource, params) {
    this.setHeader();
    const formData = new FormData();
    formData.append('media', params);
    return axios.post(`${resource}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  update(resource, slug, params) {
    this.setHeader();
    return axios.put(`${resource}/${slug}`, params);
  },

  put(resource, params, options = {}) {
    this.setHeader();
    this.setSignature({ body: params });
    return axios.put(`${resource}`, params, { ...options });
  },

  patch(resource, params, options = {}) {
    this.setHeader();
    return axios.patch(`${resource}`, params, { ...options });
  },

  delete(resource) {
    this.setHeader();
    return axios.delete(resource);
  },
};

export default ApiService;