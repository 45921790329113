import ApiService from './api.service';
import { saveToken, destroyToken } from './jwt.service';

export const VERIFY_PHONE_NUMBER = 'verifyPhoneNumber';
export const GET_OTP_REGISTER = 'requestOTPRegister';
export const REGISTER_MERCHANT = 'registerMerchant';
export const GET_OTP_WALLET = 'requestOTPWallet';
export const REGISTER_WALLET = 'registerWallet';

export const SET_TOKEN_MERCHANT = 'setTokenMerchant';
export const RESET_TOKEN_MERCHANT = 'resetTokenMerchant';

const state = {
  profile: {},
  merchant_profile: {},
};

const getters = {
  profile(state) {
    return state.profile;
  },
  merchant_profile(state) {
    return state.merchant_profile;
  },
};

const actions = {
  [VERIFY_PHONE_NUMBER](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/merchant/verify-phone', param)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [REGISTER_MERCHANT](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post('api/merchant', param)
        .then(async ({ data }) => {
          await context.commit(SET_TOKEN_MERCHANT, data.data);
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_OTP_REGISTER](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.put('api/merchant/otp', param)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_OTP_WALLET](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.put('api/merchant/wallet/otp', param)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [REGISTER_WALLET](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post('api/merchant/wallet', param)
        .then(async ({ data }) => {
          await context.commit(RESET_TOKEN_MERCHANT);
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [SET_TOKEN_MERCHANT](state, data) {
    state.merchant_profile = data;
    saveToken(JSON.stringify({ merchant: data.api_token }));
  },
  [RESET_TOKEN_MERCHANT](state) {
    // console.log('reset', state.merchant_profile);
    state.merchant_profile = {};
    destroyToken();
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};